import {
  AppName,
  DefaultLayout,
  PageContentWrapper,
  PageHeaderImage,
} from "Components";
import styled from "styled-components";
import { TabContent, Tabs } from "Components/Tabs";
import SetFan from "./SetFan";
import SetLive from "./SetLive";
import { GlobalStyles } from "styled";
import { useParams } from "react-router-dom";
import { ReactComponent as ClipboardIcon } from "assets/svg/clipboard.svg";
import FanProfile from "./FanProfile";
import { ReactComponent as UsersIcon } from "assets/svg/users.svg";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { ReactComponent as ClipboardFillIcon } from "assets/svg/clipboard-fill.svg";
import { SetBioReporting } from "./SetBio";
import { SvgDashboard } from "melodies-source/Svgs/Dashboard";
import { ReactComponent as SvgMicrophone } from "assets/svg/microphone.svg";

export const Reporting = () => {
  const { artistId } = useParams<{ artistId: string }>();
  const { customApp } = useCustomAppContext();
  const path = window.location.pathname.split("/")?.pop();

  const tabs = [
    ...(customApp
      ? []
      : [
          {
            param: "fan-profile",
            label: "Fan Profile",
            content: <FanProfile />,
            icon: <UsersIcon />,
            hasHorizontalPadding: false,
            header: "default",
          },
        ]),
    {
      param: "set-live",
      label: `${customApp?.company.name || "SET"}.Live`,
      content: <SetLive />,
      header: "live",
      icon: customApp ? (
        <ClipboardFillIcon width={24} height={24} />
      ) : (
        <SvgMicrophone width={26} height={26} />
      ),
    },
    ...(customApp
      ? []
      : [
          {
            param: "set-fan",
            label: `${customApp?.company.name || "SET"}.Fan`,
            content: <SetFan />,
            icon: <ClipboardIcon width={24} height={24} />,
            header: "fan",
          },
        ]),
    {
      param: "set-bio",
      label: `${customApp?.company.name || "SET"}.Bio`,
      content: <SetBioReporting />,
      icon: <SvgDashboard width={26} height={26} />,
      header: "bio",
    },
  ];

  const activeTab = tabs?.find(({ param }) => param === path);

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <PageHeaderImage app={activeTab.header as AppName} />
      <PageContentWrapper title="Reporting">
        <StyledTabs tabs={tabs} basePath={`/${artistId}/reporting`} />
      </PageContentWrapper>
    </DefaultLayout>
  );
};

const StyledTabs = styled(Tabs)`
  ${TabContent} {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;
