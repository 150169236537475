import React, { useState } from "react";
import { Body1, H1 } from "melodies-source/Text";
import styled from "styled-components";
import { Card } from "melodies-source/Card";
import { Button } from "melodies-source/Button";
import Lottie from "react-lottie-player";
import { AppName } from "Components";
import { Spinner } from "melodies-source/Spinner";
import { SvgInformationAlt } from "melodies-source/Svgs/InformationAlt";
import { VideoModal } from "./VideoModal";
import { Video } from "Routes/HelpCenter";
import { SvgRight } from "melodies-source/Svgs/Right";

interface DefaultAppLandingProps {
  app: AppName;
  title: React.ReactNode;
  body: React.ReactNode;
  onAction: VoidFunction;
  actionText: string;
  onSecondaryAction?: VoidFunction;
  video?: Video;
  animation?: object;
}

const colors = {
  button: {
    live: { backgroundColor: "var(--set-live-primary)", color: "#fff" },
    fan: { backgroundColor: "var(--set-fan-primary)", color: "#1B0076" },
    bio: { backgroundColor: "var(--set-bio-primary)", color: "#fff" },
  },
  circle: {
    live: "linear-gradient(224.92deg, #E71F3D 13.35%, #67000F 92.93%)",
    fan: "linear-gradient(225.39deg, #2BC0E4 12.5%, #1A86B4 100%)",
    bio: "linear-gradient(265.3deg, #9747ff 0.74%, #5701b7 99.26%)",
  },
};

export const DefaultAppLanding = ({
  app,
  title,
  body,
  onAction,
  actionText,
  animation,
  video,
}: DefaultAppLandingProps) => {
  const [videoModal, setVideoModal] = useState(false);
  if (!app) {
    return <Spinner style={{ height: "100%" }} />;
  }
  const buttonStyling = colors.button[app];
  const circleStyling = colors.circle[app];
  return (
    <StyledCard isElevated>
      <Content>
        <MainColumn>
          <H1>{title}</H1>
          {typeof body === "string" ? <Body1>{body}</Body1> : body}
        </MainColumn>
        <ImageColumn>
          <Animation animationData={animation} play />
          <AnimationBG style={{ background: circleStyling }} />
        </ImageColumn>
        <ActionButton
          onClick={onAction}
          rightIcon={<SvgRight />}
          nudgeRight
          style={buttonStyling}
        >
          {actionText}
        </ActionButton>
        {video && (
          <VideoButton
            onClick={() => setVideoModal(true)}
            text
            rightIcon={<SvgInformationAlt />}
          >
            Learn More
          </VideoButton>
        )}
        {videoModal && (
          <VideoModal video={video} autoPlay open setOpen={setVideoModal} />
        )}
      </Content>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  max-width: 940px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px 30px 32px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "mainContent imageContent" "mainAction videoAction";
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 54px;
  width: 100%;

  ${({ theme }) => theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas: "imageContent" "mainContent" "mainAction" "videoAction";
    align-items: center;
    padding: 0;
    gap: 0;
    max-width: 440px;
    align-self: center;
  }
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 440px;
  grid-area: mainContent;
  align-self: flex-start;

  ${H1} {
    color: #1b0076;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
  }
  ${Body1} {
    color: #333333;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    ${Body1} {
      max-width: 336px;
    }
  }

  ${({ theme }) => theme.media.mobile} {
    ${H1} {
      font-size: 26px;
      line-height: 36px;
    }
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  position: relative;
  aspect-ratio: 0.95;
  width: 100%;
  max-width: 350px;
  grid-area: imageContent;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.desktop} {
    aspect-ratio: 0.82;
    margin-bottom: 0;
  }
`;

const AnimationBG = styled.div`
  border-radius: 50%;
  position: absolute;
  aspect-ratio: 1;
  width: 350px;
  z-index: 0;

  background: linear-gradient(265.3deg, #9747ff 0.74%, #5701b7 99.26%);
  box-shadow: 0px 0px 7.97486px 2.52169px rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: calc(95% - 20px);
  }
`;

const Animation = styled(Lottie)`
  width: 110%;
  height: min-content;
  position: absolute;
  z-index: 1;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: min-content;
    right: -5px;
  }
`;

const ActionButton = styled(Button)`
  height: 54px;
  width: min-content;
  border-radius: 15px;
  width: 230px;
  grid-area: mainAction;

  & > div {
    margin-left: 15px;
  }

  &:hover {
    background-color: #4e00a6;
  }

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    height: 40px;
    margin-top: 24px;
  }
`;

export const VideoButton = styled(Button)`
  width: 230px;
  place-self: center;
  grid-area: videoAction;
  // &:hover {
  // }

  ${({ theme }) => theme.media.mobile} {
    margin-top: 16px;
    width: 100%;
  }
`;
